import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokSectionV2Type } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokSectionV2: FC<
  StoryblokComponentProps<StoryblokSectionV2Type>
> = ({ blok }) => {
  const { background, content, variant } = blok

  const children = <StoryblokComponentsRenderer bloks={content} />

  if (variant === 'content') {
    return (
      <section {...storyblokEditable(blok)} className="container">
        {children}
      </section>
    )
  }

  if (variant === 'landing') {
    return (
      <section
        {...storyblokEditable(blok)}
        className={clsx(
          'py-9 desktop:py-12',
          background === 'brand-low' && 'bg-light-brand-low',
        )}
      >
        <div className="container">{children}</div>
      </section>
    )
  }

  return null
}
